import Vue from "vue";

/*TITLE*/
document.title = "Cármenes de la Sierra 2 | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Cármenes de la Sierra 2";
Vue.prototype.$subtitle = "32  modernos chalets adosados con piscina individual";

/*INTRO*/
Vue.prototype.$promoter = "Cármenes de la Sierra 2";
Vue.prototype.$introSubtitle = "32  modernos chalets adosados con piscina individual";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-linkfloor-feudal-natural-copia--20230112120125.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "master-bath-linkfloor-feudal-natural-vela-natural-copia--20230112120144.jpg";
Vue.prototype.$image_bath2 = "auxiliar-bath-vela-natural-feudal-natural-copia--20230112120112.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "cam-gen-cenital--20230112120144.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-linkfloor-feudal-natural-copia--20230112120125.jpg",
  },
  {
    src: "salon-linkfloor-feudal-grey-copia--20230112120132.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "master-bath-linkfloor-feudal-natural-vela-natural-copia--20230112120144.jpg",
  },
  {
    src: "master-bath-linkfloor-feudal-grey-vela-natural-copia--20230112120156.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "auxiliar-bath-vela-natural-feudal-natural-copia--20230112120112.jpg",
  },
  {
    src: "auxiliar-bath-vela-linkfloor-natural-feudal-grey-copia--20230112120118.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "cam-gen-cenital--20230112120144.jpg",
  },
  {
    src: "cam-gen-frontal-aerea-recorte--20230112120152.jpg",
  },
  {
    src: "cam-gen-frontal-aerea--20230112120158.jpg",
  },
  {
    src: "cam-gen-frontal-der--20230112120104.jpg",
  },
  {
    src: "cam-gen-frontal-izq--20230112120114.jpg",
  },
  {
    src: "cam-gen-lateral-alta--20230112120126.jpg",
  },
  {
    src: "cam-gen-medianeria--20230112120134.jpg",
  },
  {
    src: "cam-gen-lateral--20230112120144.jpg",
  },
  {
    src: "cam-gen-trasera-patios--20230112120153.jpg",
  },
  {
    src: "cam-gen-trasera--20230112120103.jpg",
  },
  {
    src: "cam-general--20230112120120.jpg",
  },
  {
    src: "cam-parcela-20--20230112120129.jpg",
  },
  {
    src: "cam-viv-8-azotea--20230112120135.jpg",
  },
  {
    src: "cam-viv-8-piscina--20230112120142.jpg",
  },
  {
    src: "cam-viv-25-jardin--20230112120152.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/E3thulXJG4o";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20tablas%20de%20daimiel%20genil%2018001%20granada",
    text: "Calle Tablas de Daimiel, Genil, 18001 Granada",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=san%20juan%20de%20dios%201%2018001%20granada",
    text: "San Juan de Dios 1, 18001 Granada",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:958049404",
    text: "958049404",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@gralusa.com",
    text: "info@gralusa.com",
  },
];
